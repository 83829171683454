import { Link } from 'react-router-dom';
import PageContent from '../components/PageContent';
import useDocumentTitle from '../hooks/useDocumentTitle';

function CranesPage() {
  useDocumentTitle('GAL-Portal Cranes');
  const linkStyle = {
    margin: '1rem',
    padding: '1rem',
    textDecoration: 'none',
    color: 'white',
    backgroundColor: 'rgb(119, 173, 133, 0.85)',
  };
  return (
    <PageContent title="Portal cranes">
      <div className="container">
        <div className="card">
          <h2>Portal Cranes</h2>
          <img
            className="picture-about"
            src={require('../d172fa8a6d44bb4fdfa2e285e68a6aa8_thumb.jpg')}
            alt="cranes picture"
          />
          <p>
            Authorized representative of SMM as per cranes leasing supplier,
            won tenders for supplying cranes in Pakistan, Spain, South Africa
            and so on.
          </p>
          <p>Phone num.: +357 22 053837 Mail: info@greenassetsleasing.com</p>
        </div>
      </div>
      <Link to=".." relative="route" style={linkStyle}>
        Home
      </Link>
    </PageContent>
  );
}

export default CranesPage;
