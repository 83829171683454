import { NavLink } from 'react-router-dom';

import classes from './MainNavigation.module.css';

function MainNavigation() {
  return (
    <header className={classes.header}>
      <nav>
        <h2 className={classes.h2}>Green assets leasing</h2>
        <ul className={classes.list}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? classes.active : undefined)}
              end
            >
              MAIN
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? classes.active : undefined)}
            >
              ABOUT US
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/cranes"
              className={({ isActive }) => (isActive ? classes.active : undefined)}
            >
              PORTAL CRANES
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contacts"
              className={({ isActive }) => (isActive ? classes.active : undefined)}
            >
              CONTACTS
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;
