import MainNavigation from '../components/MainNavigation';
import PageContent from '../components/PageContent';

function ErrorPage() {
  return (
    <>
      <MainNavigation />
      <PageContent title="An error occurred!">
        <p>Could not find this page!</p>
      </PageContent>
    </>
  );
}

export default ErrorPage;
