import { Link } from 'react-router-dom';
import PageContent from '../components/PageContent';
import useDocumentTitle from '../hooks/useDocumentTitle';

function AboutPage() {
  useDocumentTitle('GAL-About');
  const linkStyle = {
    margin: '1rem',
    padding: '1rem',
    textDecoration: 'none',
    color: 'white',
    backgroundColor: 'rgb(119, 173, 133, 0.85)',
  };
  return (
    <PageContent title="About">
      <div className="container">
        <div className="card">
          <h2>About us</h2>
          <img
            className="picture-about"
            src={require('../3bc243c6bd7f3489f4d552b8070a5988_720x352.png')}
            alt="about"
          />
          <p>
            On this page you will find the latest information about us. Our company is constantly
            developing and growing. We provide a wide range of services providing excellent
            service...
          </p>
        </div>

        <div className="card">
          <h2>Green energy</h2>
          <img
            className="picture-about"
            src={require('../549392511a4c6c8993fa8bd0750c737e_720x406.jpg')}
            alt="about"
          />
          <p>
            Are you thinking about going green and using solar energy to power your home? Well, you
            might choose to hire a solar contractor who can install the relevant solar panels and
            get your home connected to the grid. However, it’s quite costly to do so and solar power
            leasing or a solar power PPA ( Purchase Power Agreement ) is yet another alternative you
            should consider. The initial cost of installation is considerably lower thus making the
            whole endeavor affordable.
          </p>
        </div>

        <div className="card">
          <h2>Assets</h2>
          <img
            className="picture-about"
            src={require('../967e7765fe97beea5d5353724e590eea_720x480.jpg')}
            alt="about"
          />
          <p>
            These solar leasing programs have become quite popular for many reasons. First,
            installing solar power is quite expensive since it involves buying solar panels and
            installing them on your roof. Secondly, you can save a lot of money on your utilities
            every end of the month. Therefore, if you’re tired of spending too much money on your
            lighting and heating bills, solar power leasing is the best way to go. You should find a
            good company and get prepared to get electricity in your home for the most affordable
            rates.
          </p>
        </div>
      </div>

      <Link to=".." relative="path" style={linkStyle}>
        Home
      </Link>
    </PageContent>
  );
}

export default AboutPage;
