import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomePage from './pages/Home';
import AboutPage from './pages/About';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';
import ContactsPage from './pages/Contacts';
import CranesPage from './pages/Cranes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'about', element: <AboutPage /> },
      { path: 'cranes', element: <CranesPage /> },
      { path: 'contacts', element: <ContactsPage /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
