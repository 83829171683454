import { Link } from 'react-router-dom';
import PageContent from '../components/PageContent';
import useDocumentTitle from '../hooks/useDocumentTitle';

function ContactsPage() {
  useDocumentTitle('GAL-Contacts');
  const linkStyle = {
    margin: '1rem',
    padding: '1rem',
    textDecoration: 'none',
    color: 'white',
    backgroundColor: 'rgb(119, 173, 133, 0.85)',
  };
  return (
    <PageContent title="Contacts">
      <div className="container">
        <div className="card">
          <h2>Our Contacts</h2>
          <img
            className="picture-about"
            src={require('../967e7765fe97beea5d5353724e590eea_720x480.jpg')}
            alt="about"
          />
          <p>Address: Neas 'Egkomis, 33 DOMS BUSINESS CENTER 'Egkomi, 2409, Lefkosia, Cyprus</p>
          <p>Phone num.: +357 22 053837 Mail: info@greenassetsleasing.com</p>
        </div>
      </div>
      <Link to=".." relative="route" style={linkStyle}>
        Home
      </Link>
    </PageContent>
  );
}

export default ContactsPage;
